import { flow, isEmpty, trim } from 'lodash';

import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

export const QUESTION_TYPES = Object.freeze({
  MCQ: 'MultipleChoiceQuestion',
  STQ: 'ShortTextQuestion',
  FBQ: 'FillInTheBlanksQuestion',
  LTQ: 'LongTextQuestion',
});

export const QUESTION_TYPE_ABBREVIATIONS = Object.freeze({
  MultipleChoiceQuestion: 'MCQ',
  ShortTextQuestion: 'STQ',
  FillInTheBlanksQuestion: 'FBQ',
  LongTextQuestion: 'LTQ',
});

export const QUESTION_FEATURE_TYPES = Object.freeze({
  AssignmentQuestion: 'feature_assignment_question',
  AssignmentExamStyle: 'feature_assignment_exam_style',
  PracticeBattle: 'feature_practice_battle',
  PracticeExamStyle: 'feature_practice_exam_style',
  PracticeStrengthTest: 'feature_practice_strength_test',
  SectionQuestion: 'feature_section_question',
  ExerciseQuestion: 'feature_exercise_question',
  MultiPartQuestion: 'feature_multi_part_question',
});

function previewLength(previewChunks) {
  return previewChunks.reduce((accumulator, chunk) => accumulator + chunk.length, 0);
}

export function getContentWithoutStars(content) {
  return content.replace(/[\u2605-\u2606]{3}\s*|(<span>[\u2605-\u2606]<\/span>){3}\s*/, '');
}

function createPreview(htmlText) {
  let preview = htmlText.replace(/\s{1,}/g, ' ');
  preview = getContentWithoutStars(preview);
  preview = preview.replace(/^(\s)*/, ''); // remove initial spaces if any
  preview = preview.replace(/^(\d{1,}\.*)*\s*/, ''); // numbers like 0 or 1.1.2 and spaces after
  return preview;
}

export function dangerouslyGetUnescapedText(questionHtml, maxLength = 240) {
  const div = document.implementation.createHTMLDocument('div').body;
  div.innerHTML = questionHtml;

  const ftbqElements = div.getElementsByTagName('kog-ftbq');
  while (ftbqElements.length > 0) {
    ftbqElements[0].parentNode.replaceChild(document.createTextNode('______'), ftbqElements[0]);
  }

  const previewChunks = [];
  for (let i = 0; i < div.children.length; i += 1) {
    const child = div.children[i];
    const previewChunk = createPreview(child.textContent ?? '');
    if (previewChunk) {
      previewChunks.push(previewChunk);
    }
    if (previewLength(previewChunks) > maxLength) {
      break;
    }
  }

  const preview = previewChunks.join(' ').trim();

  return (
    preview ||
    "This question doesn't have a short version, please click here to preview this question."
  );
}

export function getQuestionTypeText(question) {
  switch (question.type) {
    case QUESTION_TYPES.MCQ:
      return 'Multiple choice question';
    case QUESTION_TYPES.STQ:
      return 'Short text question';
    case QUESTION_TYPES.FBQ:
      return 'Fill in the blanks question';
    case QUESTION_TYPES.LTQ:
      return 'Long text question';
    default:
      return '';
  }
}

export function getQuestionTypeTextByAbbreviation(questionType) {
  return getQuestionTypeText({ type: QUESTION_TYPES[questionType] });
}

export function getCondensedQuestionTypeByAbbreviation(questionType) {
  return getQuestionTypeTextByAbbreviation(questionType).replace('question', '').trim();
}

export function getEmptyResponseForQuestion(question) {
  const questionType = question.type;
  switch (questionType) {
    case QUESTION_TYPES.MCQ:
      return {
        user_response: [],
        response_type: RESPONSE_TYPES.MCR,
      };
    case QUESTION_TYPES.STQ:
      return {
        user_response: '',
        response_type: RESPONSE_TYPES.STR,
      };
    case QUESTION_TYPES.FBQ: {
      const emptyAnswersForBlanks = {};
      question.answers_set.forEach(answer => {
        emptyAnswersForBlanks[answer.html_element_uid] = '';
      });

      return {
        user_response: emptyAnswersForBlanks,
        response_type: RESPONSE_TYPES.FBR,
      };
    }
    case QUESTION_TYPES.LTQ:
      return {
        user_response: '',
        response_type: RESPONSE_TYPES.LTR,
      };
    default:
      return '';
  }
}

export function getQuestionTypeAbbreviation(questionType) {
  return QUESTION_TYPE_ABBREVIATIONS[questionType];
}

export function isMCQ(question) {
  return !!question && question.type === QUESTION_TYPES.MCQ;
}

export function isSTQ(question) {
  return !!question && question.type === QUESTION_TYPES.STQ;
}

export function isFBQ(question) {
  return !!question && question.type === QUESTION_TYPES.FBQ;
}

export function isLTQ(question) {
  return !!question && question.type === QUESTION_TYPES.LTQ;
}

const isBlankString = flow(trim, isEmpty);

export function getValidationErrorsForFBQ(question) {
  const errors = [];
  if (isBlankString(question.question_html)) {
    errors.push('Please fill in question body');
  }
  if (isEmpty(question.answers_set)) {
    errors.push('Please add at least one answer');
  } else {
    const emptyAnswers = question.answers_set
      // eslint-disable-next-line no-underscore-dangle
      .filter(answer => !answer._removed)
      .filter(answer => isEmpty(answer.answers));
    if (!isEmpty(emptyAnswers)) {
      errors.push('Please fill in all the answers');
    }
  }
  return errors;
}

export function getValidationErrorsForSTQ(question) {
  const errors = [];
  if (isBlankString(question.question_html)) {
    errors.push('Please fill in question body');
  }
  if (isBlankString(question.primary_accepted_answers)) {
    errors.push('No correct answers provided');
  }
  if (isBlankString(question.answer_explanation_html)) {
    errors.push('The answer explanation is missing.');
  }
  return errors;
}

export function getValidationErrorsForMCQ(question) {
  const errors = [];
  if (isBlankString(question.question_html)) {
    errors.push('Please fill in question body');
  }
  if (isEmpty(question.multiplechoicequestionchoice_set)) {
    errors.push('Please add at least one answer');
  } else {
    const emptyAnswers = question.multiplechoicequestionchoice_set.filter(answer =>
      isEmpty(answer?.answer_html),
    );
    if (!isEmpty(emptyAnswers)) {
      errors.push('Please make sure to fill in all the answers');
    }
  }

  return errors;
}

export function getValidationErrorsForLTQ(question) {
  if (isBlankString(question.question_html)) {
    return ['Please fill in question body'];
  }
  return [];
}

export function getValidationErrors(question) {
  switch (question.type) {
    case QUESTION_TYPES.FBQ:
      return getValidationErrorsForFBQ(question);
    case QUESTION_TYPES.MCQ:
      return getValidationErrorsForMCQ(question);
    case QUESTION_TYPES.STQ:
      return getValidationErrorsForSTQ(question);
    case QUESTION_TYPES.LTQ:
      return getValidationErrorsForLTQ(question);
    default:
      throw new Error(`Unexpected question type: ${question.type}`);
  }
}

export function getFtbUIDsInOrder(questionHtml) {
  const questionElement = document.createElement('div');
  questionElement.innerHTML = questionHtml;
  const fbqEls = [...questionElement.getElementsByTagName('kog-ftbq')];
  return fbqEls.map(el => el.attributes['element-uid'].nodeValue);
}

function getFbqBlanksCount(question) {
  if (question.answers_set) {
    return question.answers_set.length;
  }
  return getFtbUIDsInOrder(question.question_html).length;
}

export function getQuestionDescription(question) {
  if (isFBQ(question)) {
    const blanksCount = getFbqBlanksCount(question);
    return `${getQuestionTypeText(question)}, ${blanksCount} ${
      blanksCount === 1 ? 'blank' : 'blanks'
    }`;
  }

  return getQuestionTypeText(question);
}

function getSubjectNodeMappingsForTooltip(question) {
  if (!question.sub_topics) {
    return '';
  }
  const ul = document.createElement('ul');
  ul.className = 'list-unstyled margin-top-fine-1 margin-bottom-fine-1';
  ul.style.marginLeft = '2px';
  ul.style.marginRight = '2px';
  question.sub_topics.forEach(subTopic => {
    const li = document.createElement('li');
    li.textContent = `${subTopic}`;
    ul.appendChild(li);
  });
  return ul.outerHTML;
}

function STQPreviewFormat(solution) {
  const { answer_explanation_html: answerExplanationHtml } = solution;

  return {
    solutionFormat: solution,
    explanationFormat: answerExplanationHtml,
  };
}
function MCQPreviewFormat(solution) {
  let answerExplanationHtml;
  const solutionObjects = Array.isArray(solution) ? solution : [solution];
  const solutionIds = solutionObjects.map(({ id }) => id);

  if (solutionObjects.length === 1) {
    answerExplanationHtml = solutionObjects[0].answer_explanation_html;
  } else {
    const explanationListItems = solutionObjects.map(
      ({ answer_explanation_html: explanation }) => `<li>${explanation}</li>`,
    );
    answerExplanationHtml = `<ul>${explanationListItems.join('')}</ul>`;
  }

  return {
    solutionFormat: solutionIds,
    explanationFormat: answerExplanationHtml,
  };
}
function FBQPreviewFormat(solution) {
  const { explanation, correct_answers: correctAnswers } = solution;

  const choicesUidMap = [];
  Object.keys(correctAnswers).forEach(HtmlElementUid => {
    choicesUidMap.push({
      ...correctAnswers[HtmlElementUid],
      html_element_uid: HtmlElementUid,
    });
  });

  return { solutionFormat: choicesUidMap, explanationFormat: explanation };
}

function questionPreviewSolutionFormatter(question) {
  if (isSTQ(question)) {
    return STQPreviewFormat;
  }
  if (isMCQ(question)) {
    return MCQPreviewFormat;
  }
  if (isFBQ(question)) {
    return FBQPreviewFormat;
  }
  return () => question;
}

export function questionPreviewSolutionFormat(question, solution) {
  const formatter = questionPreviewSolutionFormatter(question);

  const previewFormat = formatter(solution);
  return {
    ...question,
    solution: previewFormat.solutionFormat,
    explanation: previewFormat.explanationFormat,
  };
}

export function extractSolutionFromPracticeItem(question, practiceItem) {
  if (isMCQ(question)) {
    const solutionObjects = Array.isArray(practiceItem.solution)
      ? practiceItem.solution
      : [practiceItem.solution];
    const solutionIds = solutionObjects.map(({ id }) => id);
    return solutionIds;
  }
  if (isFBQ(question)) {
    const correctAnswersList = [];
    const { correct_answers: correctAnswers } = practiceItem.solution;

    Object.keys(correctAnswers).forEach(HtmlElementUid => {
      correctAnswersList.push({
        ...correctAnswers[HtmlElementUid],
        html_element_uid: HtmlElementUid,
      });
    });
    return correctAnswersList;
  }
  return question.solution;
}

export function extractUserResponseWithCorrectnessFromPracticeItem(question, practiceItem) {
  switch (question.type) {
    case QUESTION_TYPES.FBQ:
      if (practiceItem.solution && practiceItem.solution.correct_answers) {
        const userResponseCorrectness = {};
        const correctAnswers = practiceItem.solution?.correct_answers;
        Object.entries(correctAnswers).forEach(([key, value]) => {
          userResponseCorrectness[key] = value.correct;
        });
        return {
          user_response: practiceItem.answerToSubmit,
          user_response_correctness: userResponseCorrectness,
        };
      }
      break;
    case QUESTION_TYPES.STQ:
      return {
        user_response: practiceItem.answerToSubmit,
        user_response_correctness: practiceItem.user_answer?.is_correct,
      };
    default:
      break;
  }
  return { user_response: practiceItem.answerToSubmit };
}

export function getQuestionValidatableContent(question) {
  if (!question) return [];
  const htmlFields = [];

  switch (question.type) {
    case QUESTION_TYPES.MCQ: {
      const choices = question.multiplechoicequestionchoice_set;
      const correctChoices = choices.filter(choice => choice.is_correct);

      htmlFields.push(
        question.question_html,
        question.explanation_html,
        ...choices.map(choice => choice.answer_html),
        ...correctChoices.map(choice => choice.answer_explanation_html),
      );
      break;
    }
    case QUESTION_TYPES.STQ:
    case QUESTION_TYPES.LTQ:
      htmlFields.push(question.question_html, question.answer_explanation_html);
      break;
    case QUESTION_TYPES.FBQ:
      htmlFields.push(
        question.question_html,
        question.explanation_html,
        ...(question.answers_set?.map(answer => answer.explanation_html) || []),
      );
      break;
    default:
      break;
  }
  return htmlFields.filter(Boolean);
}

export default function questionUtilityService() {
  return {
    getQuestionTypeText,
    getQuestionTypeTextByAbbreviation,
    getCondensedQuestionTypeByAbbreviation,
    getSubjectNodeMappingsForTooltip,
    getContentWithoutStars,
    getFtbUIDsInOrder,
    getFbqBlanksCount,
    getQuestionDescription,
    dangerouslyGetUnescapedText,
    questionPreviewSolutionFormat,
    extractSolutionFromPracticeItem,
    extractUserResponseWithCorrectnessFromPracticeItem,
    getQuestionValidatableContent,
    isMCQ,
    isSTQ,
    isFBQ,
    isLTQ,
    QUESTION_TYPES,
  };
}
