const RoutesMixin = {
  computed: {
    subjectClassSlug() {
      return this.$route.params.classSlug;
    },
    classId() {
      return this.$route.params.cid ? Number(this.$route.params.cid) : undefined;
    },
    subjectId() {
      return this.$route.params.sid ? Number(this.$route.params.sid) : undefined;
    },
    nodeSlug() {
      return this.$route.params.nodeSlug;
    },
    nodeId() {
      return this.$route.params.nodeId ? Number(this.$route.params.nodeId) : undefined;
    },
  },
  methods: {
    goToPage(url, isExternalLink = false) {
      if (isExternalLink) {
        window.location = url;
      } else {
        this.$router.push(url).catch(error => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
      }
    },
    getNodeUrl(node, routeName) {
      const route = {
        name: routeName,
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      };
      return this.$router.resolve(route).href;
    },
  },
};

export default RoutesMixin;
